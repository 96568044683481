export class profile {
    fname: string;
    lname: string;
    gender: string;
    email: string;
    mobile: string;
}

export class profileObj {
    profileData: profile[];
}
